<template>
  <div class="container">
    <div class="row">
      <div class="col-4">
        <span>Background color</span>
        <div class="row">
          <div class="col-10">
            <input class="form-control" v-model="backgroundColor">
          </div>
          <div class="col-2">
            <input type="color" class="form-control form-control-color" v-model="backgroundColor" title="Choose your color">
          </div>
        </div>
        <span>Base text color</span>
        <div class="row">
          <div class="col-10">
            <input class="form-control" v-model="baseTextColor">
          </div>
          <div class="col-2">
            <input type="color" class="form-control form-control-color" v-model="baseTextColor" title="Choose your color">
          </div>
        </div>
        <span>Shaded text color</span>
        <div class="row">
          <div class="col-10">
            <input class="form-control" v-model="shadedTextColor">
          </div>
          <div class="col-2">
            <input type="color" class="form-control form-control-color" v-model="shadedTextColor" title="Choose your color">
          </div>
        </div>
        <span>Card background color</span>
        <div class="row">
          <div class="col-10">
            <input class="form-control" v-model="cardBackgroundColor">
          </div>
          <div class="col-2">
            <input type="color" class="form-control form-control-color" v-model="cardBackgroundColor" title="Choose your color">
          </div>
        </div>
        <span>Operator key background color</span>
        <div class="row">
          <div class="col-10">
            <input class="form-control" v-model="accentColor">
          </div>
          <div class="col-2">
            <input type="color" class="form-control form-control-color" v-model="accentColor" title="Choose your color">
          </div>
        </div>
        <span>Operator key text color</span>
        <div class="row">
          <div class="col-10">
            <input class="form-control" v-model="accentTextColor">
          </div>
          <div class="col-2">
            <input type="color" class="form-control form-control-color" v-model="accentTextColor" title="Choose your color">
          </div>
        </div>
        <span>Digit key text color</span>
        <div class="row">
          <div class="col-10">
            <input class="form-control" v-model="digitTextColor">
          </div>
          <div class="col-2">
            <input type="color" class="form-control form-control-color" v-model="digitTextColor" title="Choose your color">
          </div>
        </div>
        <span>Digit key background color</span>
        <div class="row">
          <div class="col-10">
            <input class="form-control" v-model="digitBackgroundColor">
          </div>
          <div class="col-2">
            <input type="color" class="form-control form-control-color" v-model="digitBackgroundColor" title="Choose your color">
          </div>
        </div>
        <span>Theme name</span>
        <div class="row">
          <div class="col-12">
            <input class="form-control" v-model="themeName">
          </div>
        </div>
        <br>
        <h3><i class="bi bi-box-arrow-down"></i> Export</h3>
        <!--eslint-disable-next-line-->
        <textarea class="form-control" rows="5">{{ JSON.stringify({
            name: themeName,
            backgroundColor: backgroundColor,
            foregroundColor: baseTextColor,
            shadedColor: shadedTextColor,
            cardColor: cardBackgroundColor,
            accentColor: accentColor,
            accentText: accentTextColor,
            digitText: digitTextColor,
            digitKeys: digitBackgroundColor,
            shadows: "#00000000"
          }) }}
        </textarea>
      </div>
      <div class="col-8 appView" :style="{ backgroundColor: backgroundColor }">
        <br>
        <br>
        <div class="container">
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="row">
              <div class="col-8">
                <h2 :style="{color: baseTextColor}" class="spacegrotesk">💸 currency</h2>
              </div>
              <div class="col-4">
                <h2 :style="{color: baseTextColor}" style="text-align: right;"><i class="bi bi-gear"></i></h2>
              </div>
            </div>
            <br>
            <div class="card" :style="{backgroundColor: cardBackgroundColor}">
              <div class="card-body">
                <b class="spacegrotesk" :style="{color: baseTextColor}">UNITED STATES DOLLAR 🇺🇸</b>
                <div style="text-align: right;">
                  <h2>
                    <span :style="{color: baseTextColor}">14 000 </span>
                    <span :style="{color: shadedTextColor}">$</span>
                  </h2>
                </div>
              </div>
            </div>
            <br>
            <div class="card" :style="{backgroundColor: cardBackgroundColor}">
              <div class="card-body">
                <b class="spacegrotesk" :style="{color: baseTextColor}">RUSSIAN RUBLE 🇷🇺</b>
                <div style="text-align: right;">
                  <h2>
                    <span :style="{color: baseTextColor}">1 288 770 </span>
                    <span :style="{color: shadedTextColor}">₽</span>
                  </h2>
                </div>
              </div>
            </div>

        </div>
        <div class="row">
          <br><br>
        </div>
        <div class="row justify-content-center">
  
            <div class="row justify-content-center">

                <div class="col-2">
                  <div class="key" :style="{ backgroundColor: accentColor }">
                    <h2 :style="{color: accentTextColor}"><i class="bi bi-x-square"></i></h2>
                  </div>
                </div>

                <div class="col-2">
                  <div class="key" :style="{ backgroundColor: accentColor }">
                    <h2 :style="{color: accentTextColor}"><i class="bi bi-arrow-down-up"></i></h2>
                  </div>
                </div>

                <div class="col-2">
                  <div class="key" :style="{ backgroundColor: accentColor }">
                    <h2 :style="{color: accentTextColor}"><i class="bi bi-backspace"></i></h2>
                  </div>
                </div>

                <div class="col-2">
                  <div class="key" :style="{ backgroundColor: accentColor }">
                    <h2 :style="{color: accentTextColor}">/</h2>
                  </div>
                </div>

            </div>
            <div class="row">
              <br>
            </div>
            <div class="row justify-content-center">

              <div class="col-2">
                <div class="key" :style="{ backgroundColor: digitBackgroundColor }">
                  <h2 :style="{color: digitTextColor}">7</h2>
                </div>
              </div>

              <div class="col-2">
                <div class="key" :style="{ backgroundColor: digitBackgroundColor }">
                  <h2 :style="{color: digitTextColor}">8</h2>
                </div>
              </div>

              <div class="col-2">
                <div class="key" :style="{ backgroundColor: digitBackgroundColor }">
                  <h2 :style="{color: digitTextColor}">9</h2>
                </div>
              </div>

              <div class="col-2">
                <div class="key" :style="{ backgroundColor: accentColor }">
                  <h2 :style="{color: accentTextColor}">X</h2>
                </div>
              </div>

            </div>

            <div class="row">
              <br>
            </div>
            <div class="row justify-content-center">

              <div class="col-2">
                <div class="key" :style="{ backgroundColor: digitBackgroundColor }">
                  <h2 :style="{color: digitTextColor}">4</h2>
                </div>
              </div>

              <div class="col-2">
                <div class="key" :style="{ backgroundColor: digitBackgroundColor }">
                  <h2 :style="{color: digitTextColor}">5</h2>
                </div>
              </div>

              <div class="col-2">
                <div class="key" :style="{ backgroundColor: digitBackgroundColor }">
                  <h2 :style="{color: digitTextColor}">6</h2>
                </div>
              </div>

              <div class="col-2">
                <div class="key" :style="{ backgroundColor: accentColor }">
                  <h2 :style="{color: accentTextColor}">+</h2>
                </div>
              </div>

            </div>

          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      backgroundColor: '#002b36',
      baseTextColor: '#eee8d5',
      shadedTextColor: '#93a1a1',
      cardBackgroundColor: '#073642',
      accentColor: '#839496',
      accentTextColor: '#eee8d5',
      digitTextColor: '#eee8d5',
      digitBackgroundColor: '#586e75',
      themeName: 'Solarized Dark'
    }
  },
  created() {
    Object.keys(this.$data).forEach(key => {
      if (localStorage.getItem(key)) {
        this[key] = localStorage.getItem(key);
      }
    });
  },
  watch: {
    backgroundColor(newVal) {
      localStorage.setItem('backgroundColor', newVal);
    },
    baseTextColor(newVal) {
      localStorage.setItem('baseTextColor', newVal);
    },
    shadedTextColor(newVal) {
      localStorage.setItem('shadedTextColor', newVal);
    },
    cardBackgroundColor(newVal) {
      localStorage.setItem('cardBackgroundColor', newVal);
    },
    accentColor(newVal) {
      localStorage.setItem('accentColor', newVal);
    },
    accentTextColor(newVal) {
      localStorage.setItem('accentTextColor', newVal);
    },
    digitTextColor(newVal) {
      localStorage.setItem('digitTextColor', newVal);
    },
    digitBackgroundColor(newVal) {
      localStorage.setItem('digitBackgroundColor', newVal);
    },
    themeName(newVal) {
      localStorage.setItem('themeName', newVal);
    }
  }
}
</script>

<style>
#app {
  font-family: Nunito, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
.card {
  border-radius: 16px;
}
.key{
  border-radius: 30px;
  text-align: center;
  padding: 1rem;
}
.appView{
  border-radius: 16px;
}
.spacegrotesk{
  font-family: 'Space Grotesk', sans-serif;
}
</style>
